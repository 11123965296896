export default {
  heading: 'Work Sans, sans-serif',
  subheading: 'Poiret One',
  body: 'Work Sans, serif',
  monospace: 'Menlo, monospace',
  display: 'Cinzel, serif',
  googleFonts: [
    'Work Sans:100,200,300,400,500,600,700,800,900',
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Poiret One',
    'Cinzel'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
